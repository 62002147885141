body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight:400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.homeLayoutContent{
  min-height: 100vh;
  background-image: url('./Images/Digital-Solutions-BG.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
 
  box-sizing: border-box;
}
.smallBoxContent{   
    padding: 10px;
    height: 77%;
    border-radius: 4px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); 
    margin: 16px auto; 
 background-color: #f1d5f2 !important;

}
